/** 
 這支檔案沒有包含所有的 shoplytics tracker，
 舊有的 shoplytics tracker 還是在 service.trackers.js 中

 This file does not include all of the shoplytics trackers; 
 the existing shoplytics trackers are still in the service.trackers.js file.
 */

const {
  ONE_PAGE_STORE_TRACKER_TYPE,
  CART_REVAMP_TRACKER_TYPE,
  CART_TRACKER_TYPE_FOR_V1_AND_V2,
  CART_TRACKER_TYPE,
  CHECKOUT_TRACKER_TYPE,
} = require('../constants/tracker');

app.run([
  '$rootScope',
  'mainConfig',
  'trackerService',
  function ($rootScope, mainConfig, trackerService) {
    const { generalEventType } = trackerService;
    const userId = mainConfig.currentUser
      ? mainConfig.currentUser['_id']
      : null;

    function buildTrackDataForEventsUserFlow(event, data, userId) {
      const { event_category, property } = data;
      // value could be false, so we need to check if it's undefined or null
      const value =
        data.value === undefined || data.value === null ? null : data.value;

      return {
        event_category,
        user_id: userId,
        data: JSON.stringify({ property, value }),
      };
    }

    function buildTrackDataWithScope(data) {
      const { scope = 'shop', ...restData } = data;

      return {
        data: JSON.stringify(restData),
        scope,
      };
    }

    function getTrackData({ event_category, user_id = userId, ...rest }) {
      return {
        event_category,
        user_id,
        data: JSON.stringify(rest),
      };
    }

    $rootScope.$on(generalEventType.MemberSignUpFormShow, (event, data) => {
      const eventName = event.name;
      const trackData = buildTrackDataForEventsUserFlow(event, data, userId);

      if (typeof shoplytics === 'function') {
        shoplytics('track', eventName, trackData);
      }
    });

    $rootScope.$on(generalEventType.MemberSignUpFormSubmit, (event, data) => {
      const eventName = event.name;
      const trackData = buildTrackDataForEventsUserFlow(event, data, userId);

      if (typeof shoplytics === 'function') {
        shoplytics('track', eventName, trackData);
      }
    });

    $rootScope.$on(generalEventType.ThirdPartySignUpClick, (event, data) => {
      const eventName = event.name;
      const trackData = buildTrackDataForEventsUserFlow(event, data, userId);

      if (typeof shoplytics === 'function') {
        shoplytics('track', eventName, trackData);
      }
    });

    $rootScope.$on(generalEventType.ThirdPartyLoginClick, (event, data) => {
      const eventName = event.name;
      const trackData = buildTrackDataForEventsUserFlow(event, data, userId);

      if (typeof shoplytics === 'function') {
        shoplytics('track', eventName, trackData);
      }
    });

    $rootScope.$on(generalEventType.MemberLoginClick, (event, data) => {
      const eventName = event.name;
      const trackData = buildTrackDataForEventsUserFlow(event, data, userId);

      if (typeof shoplytics === 'function') {
        shoplytics('track', eventName, trackData);
      }
    });

    $rootScope.$on(generalEventType.SMSVerificationCodeSend, (event, data) => {
      const eventName = event.name;
      const trackData = buildTrackDataForEventsUserFlow(event, data, userId);

      if (typeof shoplytics === 'function') {
        shoplytics('track', eventName, trackData);
      }
    });

    $rootScope.$on(generalEventType.SMSVerificationFormShow, (event, data) => {
      const eventName = event.name;
      const trackData = buildTrackDataForEventsUserFlow(event, data, userId);

      if (typeof shoplytics === 'function') {
        shoplytics('track', eventName, trackData);
      }
    });

    $rootScope.$on(
      generalEventType.SMSVerificationSendButtonClick,
      (event, data) => {
        const eventName = event.name;
        const trackData = buildTrackDataForEventsUserFlow(event, data, userId);

        if (typeof shoplytics === 'function') {
          shoplytics('track', eventName, trackData);
        }
      },
    );

    $rootScope.$on(generalEventType.SMSVerificationSubmit, (event, data) => {
      const eventName = event.name;
      const trackData = buildTrackDataForEventsUserFlow(event, data, userId);

      if (typeof shoplytics === 'function') {
        shoplytics('track', eventName, trackData);
      }
    });

    $rootScope.$on(
      generalEventType.RecaptchaVerificationStart,
      (event, data) => {
        const eventName = event.name;
        const trackData = buildTrackDataForEventsUserFlow(event, data, userId);

        if (typeof shoplytics === 'function') {
          shoplytics('track', eventName, trackData);
        }
      },
    );

    $rootScope.$on(
      generalEventType.RecaptchaVerificationComplete,
      (event, data) => {
        const eventName = event.name;
        const trackData = buildTrackDataForEventsUserFlow(event, data, userId);

        if (typeof shoplytics === 'function') {
          shoplytics('track', eventName, trackData);
        }
      },
    );

    $rootScope.$on(generalEventType.MemberSignUpInfoFormShow, (event, data) => {
      const eventName = event.name;
      const trackData = buildTrackDataForEventsUserFlow(event, data, userId);

      if (typeof shoplytics === 'function') {
        shoplytics('track', eventName, trackData);
      }
    });

    $rootScope.$on(generalEventType.MemberSignUpInfoSubmit, (event, data) => {
      const eventName = event.name;
      const trackData = buildTrackDataForEventsUserFlow(event, data, userId);

      if (typeof shoplytics === 'function') {
        shoplytics('track', eventName, trackData);
      }
    });

    $rootScope.$on(generalEventType.MemberSignUpSucceed, (event, data) => {
      const eventName = event.name;
      const trackData = buildTrackDataForEventsUserFlow(event, data, userId);

      if (typeof shoplytics === 'function') {
        shoplytics('track', eventName, trackData);
      }
    });

    $rootScope.$on(generalEventType.MemberLoginFormShow, (event, data) => {
      const eventName = event.name;
      const trackData = buildTrackDataForEventsUserFlow(event, data, userId);

      if (typeof shoplytics === 'function') {
        shoplytics('track', eventName, trackData);
      }
    });

    $rootScope.$on(generalEventType.MemberLoginSucceed, (event, data) => {
      const eventName = event.name;
      const trackData = buildTrackDataForEventsUserFlow(event, data, userId);

      if (typeof shoplytics === 'function') {
        shoplytics('track', eventName, trackData);
      }
    });

    $rootScope.$on(generalEventType.UpdateMemberInfoFormShow, (event, data) => {
      const eventName = event.name;
      const trackData = buildTrackDataForEventsUserFlow(event, data, userId);

      if (typeof shoplytics === 'function') {
        shoplytics('track', eventName, trackData);
      }
    });

    $rootScope.$on(generalEventType.UpdateMemberInfoSucceed, (event, data) => {
      const eventName = event.name;
      const trackData = buildTrackDataForEventsUserFlow(event, data, userId);

      if (typeof shoplytics === 'function') {
        shoplytics('track', eventName, trackData);
      }
    });

    $rootScope.$on(generalEventType.ORDER_NUMBER_LINK_CLICK, (event, data) => {
      const eventName = event.name;
      if (typeof shoplytics === 'function') {
        shoplytics('track', eventName, {
          user_id: userId,
          data: JSON.stringify(data),
        });
      }
    });

    $rootScope.$on(
      generalEventType.MEMBER_SUBSCRIPTION_BUTTON_CLICK,
      (event, data) => {
        const eventName = event.name;

        if (typeof shoplytics === 'function') {
          const trackData = getTrackData(data);

          shoplytics('track', eventName, trackData);
        }
      },
    );

    $rootScope.$on(
      generalEventType.MEMBER_SUBSCRIPTION_POPUP_CLICK,
      (event, data) => {
        const eventName = event.name;

        if (typeof shoplytics === 'function') {
          const trackData = getTrackData(data);
          shoplytics('track', eventName, trackData);
        }
      },
    );

    $rootScope.$on(
      ONE_PAGE_STORE_TRACKER_TYPE.MiniCartButtonClick,
      (event, data) => {
        const eventName = event.name;
        const trackData = buildTrackDataWithScope(data);

        if (typeof shoplytics === 'function') {
          shoplytics('track', eventName, trackData);
        }
      },
    );

    $rootScope.$on(
      ONE_PAGE_STORE_TRACKER_TYPE.OrderCheckoutButtonClick,
      (event, data) => {
        const eventName = event.name;
        const trackData = buildTrackDataWithScope(data);

        if (typeof shoplytics === 'function') {
          shoplytics('track', eventName, trackData);
        }
      },
    );

    $rootScope.$on(
      ONE_PAGE_STORE_TRACKER_TYPE.ProductImpression,
      (event, data) => {
        const eventName = event.name;
        const trackData = buildTrackDataWithScope(data);

        if (typeof shoplytics === 'function') {
          shoplytics('track', eventName, trackData);
        }
      },
    );

    $rootScope.$on(
      ONE_PAGE_STORE_TRACKER_TYPE.CartImpression,
      (event, data) => {
        const eventName = event.name;
        const trackData = buildTrackDataWithScope(data);

        if (typeof shoplytics === 'function') {
          shoplytics('track', eventName, trackData);
        }
      },
    );

    $rootScope.$on(ONE_PAGE_STORE_TRACKER_TYPE.CheckoutInput, (event, data) => {
      const eventName = event.name;
      const trackData = buildTrackDataWithScope(data);

      if (typeof shoplytics === 'function') {
        shoplytics('track', eventName, trackData);
      }
    });

    $rootScope.$on(generalEventType.CartMemberLoginClick, (event, data) => {
      const eventName = event.name;
      if (typeof shoplytics === 'function') {
        const trackData = getTrackData(data);
        shoplytics('track', eventName, trackData);
      }
    });

    $rootScope.$on(
      generalEventType.CartThirdPartySignUpClick,
      (event, data) => {
        const eventName = event.name;
        if (typeof shoplytics === 'function') {
          const trackData = getTrackData(data);
          shoplytics('track', eventName, trackData);
        }
      },
    );

    $rootScope.$on(
      CART_TRACKER_TYPE_FOR_V1_AND_V2.COUPON_CENTER_LINK_CLICK,
      (event, data) => {
        const eventName = event.name;
        const trackData = getTrackData(data);

        if (typeof shoplytics === 'function') {
          shoplytics('track', eventName, trackData);
        }
      },
    );

    $rootScope.$on(
      CART_REVAMP_TRACKER_TYPE.PaymentPromoOptionClick,
      (event, data) => {
        const eventName = event.name;
        const trackData = getTrackData(data);

        if (typeof shoplytics === 'function') {
          shoplytics('track', eventName, trackData);
        }
      },
    );

    $rootScope.$on(
      CART_REVAMP_TRACKER_TYPE.PaymentPromoOptionShow,
      (event, data) => {
        const eventName = event.name;
        const trackData = getTrackData(data);

        if (typeof shoplytics === 'function') {
          shoplytics('track', eventName, trackData);
        }
      },
    );

    $rootScope.$on(
      CART_REVAMP_TRACKER_TYPE.PromotionReminderClick,
      (event, data) => {
        const eventName = event.name;
        const trackData = getTrackData(data);

        if (typeof shoplytics === 'function') {
          shoplytics('track', eventName, trackData);
        }
      },
    );

    $rootScope.$on(
      CART_REVAMP_TRACKER_TYPE.PromotionReminderShow,
      (event, data) => {
        const eventName = event.name;
        const trackData = getTrackData(data);

        if (typeof shoplytics === 'function') {
          shoplytics('track', eventName, trackData);
        }
      },
    );

    $rootScope.$on(
      CART_REVAMP_TRACKER_TYPE.AddonAllListClick,
      (event, data) => {
        const eventName = event.name;
        const trackData = getTrackData(data);

        if (typeof shoplytics === 'function') {
          shoplytics('track', eventName, trackData);
        }
      },
    );

    $rootScope.$on(CART_REVAMP_TRACKER_TYPE.AddonAllListShow, (event, data) => {
      const eventName = event.name;
      const trackData = getTrackData(data);

      if (typeof shoplytics === 'function') {
        shoplytics('track', eventName, trackData);
      }
    });

    $rootScope.$on(
      generalEventType.FIXED_RECOMMENDATION_BAR_CLICK,
      (event, data) => {
        const eventName = event.name;
        const trackData = getTrackData(data);

        if (typeof shoplytics === 'function') {
          shoplytics('track', eventName, trackData);
        }
      },
    );

    // TODO: use this function for every other trackers
    const trackerCallback = (event, data) => {
      const eventName = event.name;
      const trackData = getTrackData(data);

      if (typeof shoplytics === 'function') {
        shoplytics('track', eventName, trackData);
      }
    };

    $rootScope.$on(CART_TRACKER_TYPE.ADDON_PAGE_CLICK, trackerCallback);

    $rootScope.$on(
      CART_TRACKER_TYPE_FOR_V1_AND_V2.DELIVERY_OPTION_CLICK,
      trackerCallback,
    );

    $rootScope.$on(
      CART_TRACKER_TYPE_FOR_V1_AND_V2.PAYMENT_OPTION_CLICK,
      trackerCallback,
    );

    $rootScope.$on(CHECKOUT_TRACKER_TYPE.CHECKOUT_CART_CLICK, trackerCallback);

    $rootScope.$on(
      CHECKOUT_TRACKER_TYPE.CHECKOUT_MEMBER_LOG_IN_CLICK,
      trackerCallback,
    );

    $rootScope.$on(
      CHECKOUT_TRACKER_TYPE.CHECKOUT_THIRD_PARTY_SIGN_UP_CLICK,
      trackerCallback,
    );

    $rootScope.$on(CHECKOUT_TRACKER_TYPE.CHECKOUT_NAME_INPUT, trackerCallback);

    $rootScope.$on(
      CHECKOUT_TRACKER_TYPE.CHECKOUT_PHONE_NUMBER_INPUT,
      trackerCallback,
    );

    $rootScope.$on(
      CHECKOUT_TRACKER_TYPE.CHECKOUT_SAME_RECIPIENT_CLICK,
      trackerCallback,
    );

    $rootScope.$on(
      CHECKOUT_TRACKER_TYPE.CHECKOUT_RECIPIENT_NAME_INPUT,
      trackerCallback,
    );

    $rootScope.$on(
      CHECKOUT_TRACKER_TYPE.CHECKOUT_RECIPIENT_PHONE_INPUT,
      trackerCallback,
    );

    $rootScope.$on(
      CHECKOUT_TRACKER_TYPE.CHECKOUT_RECIPIENT_ADDRESS_TYPE,
      trackerCallback,
    );
  },
]);
